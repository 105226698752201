var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '600',
        }}},[_c('el-table-column',{attrs:{"prop":"","label":"订单编号","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.contractStatus == 3)?_c('div',{style:({
                        color: 'red',
                        cursor: 'pointer',
                    })},[_vm._v(" "+_vm._s(scope.row.orderNo || '- -')+" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该订单的关联合同已作废","placement":"bottom"}},[(scope.row.contractStatus == 3)?_c('i',{staticClass:"el-icon-warning",staticStyle:{"color":"#ff912a"}}):_vm._e()])],1):_c('div',{style:({
                        color: '#2370eb',
                        cursor: 'pointer',
                    }),on:{"click":function($event){return _vm.onDetail2(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.orderNo || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"客户名称","min-width":"192"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.companyName || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"产品名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.productCategory( scope.row.productCategory ))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"用户数","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[(
                            scope.row.productCategory == 1 ||
                            scope.row.productCategory == 2 ||
                            scope.row.productCategory == 4 ||
                            scope.row.productCategory == 10
                        )?_c('span',[_vm._v(" "+_vm._s(scope.row.orderNumber || '0')+" 用户 ")]):_c('span',[_vm._v(" - - ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"年限","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[(
                            scope.row.productCategory == 1 ||
                            scope.row.productCategory == 2 ||
                            scope.row.productCategory == 4 ||
                            scope.row.productCategory == 3
                        )?_c('span',[_vm._v(" "+_vm._s(scope.row.term || 0)+" 年 ")]):_c('span',[_vm._v(" - - ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"交付时间","min-width":"150","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.deliverTime ? scope.row.deliverTime.split(' ')[0] : '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.contractStatus != 3)?[(_vm.btnP.detailsPopup)?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDetail(scope.row)}}},[_vm._v(" 详情 ")]):_vm._e(),(_vm.btnP.upData && scope.row.status == 1)?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onUpdate(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.btnP.deliver && scope.row.status == 1)?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDeliver(scope.row)}}},[_vm._v(" 交付 ")]):_vm._e()]:undefined}}],null,true)}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }