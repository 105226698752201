<template>
    <div class="deliver_box">
        <el-form label-position="left" label-width="85px" size="small">
            <el-form-item label="产品名称">
                <div class="info">
                    {{
                        $tableDataHandle.productCategory(
                            orderplan.productCategory
                        )
                    }}
                </div>
            </el-form-item>
            <el-form-item label="客户">
                <div class="info">
                    {{ orderplan.companyName || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="营业执照">
                <div class="info">
                    <img
                        v-if="
                            orderplan.businessLicenseUrl &&
                            (orderplan.businessLicenseUrl.indexOf('.jpg') !=
                                -1 ||
                                orderplan.businessLicenseUrl.indexOf('.png') !=
                                    -1 ||
                                orderplan.businessLicenseUrl.indexOf('.gif') !=
                                    -1)
                        "
                        @click="onPreview(orderplan.businessLicenseUrl)"
                        style="width: 40px"
                        :src="orderplan.businessLicenseUrl"
                        :preview-src-list="[orderplan.businessLicenseUrl]"
                    />

                    <span
                        class="pdf"
                        v-if="
                            orderplan.businessLicenseUrl &&
                            (orderplan.businessLicenseUrl.indexOf('.pdf') !=
                                -1 ||
                                orderplan.businessLicenseUrl.indexOf('.PDF') !=
                                    -1)
                        "
                        @click="onPreview(orderplan.businessLicenseUrl)"
                        style="cursor: pointer"
                    >
                        <img src="@/img/pdfUrl.png" alt=""
                    /></span>
                    <span
                        class="word"
                        v-if="
                            orderplan.businessLicenseUrl &&
                            orderplan.businessLicenseUrl.indexOf('.doc') != -1
                        "
                        @click="onPreview(orderplan.businessLicenseUrl)"
                        style="cursor: pointer"
                    >
                        <img src="@/img/word.png" alt="" />
                    </span>
                </div>
            </el-form-item>
            <el-form-item label="附件">
                <div
                    class="info"
                    v-if="
                        orderplan.contractAttachmentBOList &&
                        orderplan.contractAttachmentBOList.length > 0
                    "
                >
                    <span
                        style="margin-right: 8px"
                        class="cont"
                        v-for="item in orderplan.contractAttachmentBOList"
                        :key="item.id"
                    >
                        <img
                            v-if="
                                item.attachmentUrl &&
                                (item.attachmentUrl.indexOf('.jpg') != -1 ||
                                    item.attachmentUrl.indexOf('.png') != -1 ||
                                    item.attachmentUrl.indexOf('.gif') != -1)
                            "
                            style="width: 40px"
                            @click="onPreview(item.attachmentUrl)"
                            :src="item.attachmentUrl"
                            :preview-src-list="[item.attachmentUrl]"
                        />

                        <span
                            class="pdf"
                            v-if="
                                item.attachmentUrl &&
                                (item.attachmentUrl.indexOf('.pdf') != -1 ||
                                    item.attachmentUrl.indexOf('.PDF') != -1)
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="
                                item.attachmentUrl &&
                                item.attachmentUrl.indexOf('.doc') != -1
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </div>
            </el-form-item>
            <el-form-item label="用户数">
                <div class="info">
                    <span
                        v-if="
                            orderplan.productCategory == 1 ||
                            orderplan.productCategory == 2 ||
                            orderplan.productCategory == 4 ||
                            orderplan.productCategory == 10
                        "
                    >
                        {{ orderplan.orderNumber || 0 }} 用户
                    </span>
                    <span v-else> - - </span>
                </div>
            </el-form-item>
            <el-form-item label="年限">
                <div class="info">
                    <span
                        v-if="
                            orderplan.productCategory == 1 ||
                            orderplan.productCategory == 2 ||
                            orderplan.productCategory == 4 ||
                            orderplan.productCategory == 3
                        "
                    >
                        {{ orderplan.term || 0 }} 年
                    </span>
                    <span v-else> - - </span>
                </div>
            </el-form-item>
            <el-form-item label="开通时间" required>
                <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.openTime"
                    type="date"
                    style="width: 240px"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="到期时间" required>
                <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.expireTime"
                    style="width: 240px"
                    type="date"
                    :picker-options="expireTimeOption"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item
                label="下单名称"
                required
                v-if="
                    orderplan.productCategory == 1 ||
                    orderplan.productCategory == 2 ||
                    orderplan.productCategory == 3 ||
                    orderplan.productCategory == 12
                "
            >
                <el-input
                    style="width: 240px"
                    placeholder="输入下单名称"
                    v-model="form.companyName"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="域名"
                required
                v-if="
                    orderplan.productCategory == 3 ||
                    orderplan.productCategory == 12
                "
            >
                <el-input
                    style="width: 240px"
                    placeholder="输入域名"
                    v-model="form.domain"
                ></el-input>
            </el-form-item>
            <el-form-item label="CorpID" v-if="orderplan.productCategory == 1">
                <el-input
                    style="width: 240px"
                    placeholder="输入CorpID"
                    v-model="form.corpId"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="管理员账号"
                v-if="orderplan.productCategory == 2"
            >
                <el-input
                    style="width: 240px"
                    placeholder="输入管理员账号"
                    v-model="form.adminAccount"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="下单后台"
                required
                v-if="
                    orderplan.productCategory == 1 ||
                    orderplan.productCategory == 2 ||
                    orderplan.productCategory == 3 ||
                    orderplan.productCategory == 12
                "
            >
                <el-select
                    v-model="form.placeBackstage"
                    size="small"
                    style="width: 240px"
                    clearable
                    placeholder="请选择下单后台"
                >
                    <el-option
                        v-if="orderplan.productCategory == 1"
                        label="伽搜boss"
                        :value="0"
                    ></el-option>
                    <el-option
                        v-if="orderplan.productCategory == 2"
                        label="钱邮"
                        :value="1"
                    ></el-option>
                    <el-option
                        v-if="
                            orderplan.productCategory == 3 ||
                            orderplan.productCategory == 12
                        "
                        label="腾讯云"
                        :value="2"
                    ></el-option>
                    <el-option
                        v-if="
                            orderplan.productCategory == 3 ||
                            orderplan.productCategory == 12
                        "
                        label="阿里云"
                        :value="3"
                    ></el-option>
                    <el-option
                        v-if="
                            orderplan.productCategory == 3 ||
                            orderplan.productCategory == 12
                        "
                        label="925"
                        :value="4"
                    ></el-option>
                    <el-option
                        v-if="
                            orderplan.productCategory == 3 ||
                            orderplan.productCategory == 12
                        "
                        label="华为云"
                        :value="5"
                    ></el-option>
                    <el-option
                        v-if="orderplan.productCategory == 2"
                        label="云梦"
                        :value="6"
                    ></el-option>
                    <el-option
                        v-if="orderplan.productCategory == 2"
                        label="华中"
                        :value="7"
                    ></el-option>
                    <el-option
                        v-if="orderplan.productCategory == 2"
                        label="山总"
                        :value="8"
                    ></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { oneOrderPlan } from '@/api/order/order.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            value1: '',
            form: {
                placeBackstage: '',
            },
            contractAttachmentList: [],
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now();
                },
            },
            orderplan: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.form = {
                openTime: this.$handTime.getFormatDate(),
                companyName: row.companyName,
            };
            let data = {
                param: {
                    id: row.id,
                },
            };
            oneOrderPlan(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.orderplan = res.data;
                }
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.deliver_box {
    width: 100%;
    height: 100%;
}
.el-form-item {
    margin-bottom: 8px !important;
}
</style>
