<template>
    <div class="detail_box">
        <p class="title">交付计划</p>
        <div class="deliver_info">
            <p>
                <span>交付时间</span
                >{{
                    orderplan.deliverTime
                        ? orderplan.deliverTime.split(' ')[0]
                        : '- -'
                }}
            </p>
            <p>
                <span>产品名称</span
                >{{
                    $tableDataHandle.productCategory(orderplan.productCategory)
                }}
            </p>
            <p>
                <span>用户数</span
                >{{
                    orderplan.productCategory == 1 ||
                    orderplan.productCategory == 2 ||
                    orderplan.productCategory == 4 ||
                    orderplan.productCategory == 10
                        ? orderplan.orderNumber + ' 用户' || 0 + ' 用户'
                        : '- -'
                }}
            </p>
            <p>
                <span>年限</span
                >{{
                    orderplan.productCategory == 1 ||
                    orderplan.productCategory == 2 ||
                    orderplan.productCategory == 4 ||
                    orderplan.productCategory == 3
                        ? orderplan.term + ' 年' || 0 + ' 年'
                        : '- -'
                }}
            </p>
            <p><span>创建时间</span>{{ orderplan.createTime || '- -' }}</p>
            <p><span>创建人</span>{{ orderplan.adminName || '- -' }}</p>
        </div>
        <p class="title">交付历史</p>
        <el-table
            ref="multipleTable"
            :data="orderplan.orderPlanHistoryBOList"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column prop="createTime" label="操作时间" width="160">
            </el-table-column>
            <el-table-column prop="adminName" label="操作人员" width="75">
            </el-table-column>
            <el-table-column prop="remark" label="操作日志"> </el-table-column>
        </el-table>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" round>保 存</el-button>
        </div>
    </div>
</template>

<script>
import { oneOrderPlan } from '@/api/order/order.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            orderplan: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            oneOrderPlan(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.orderplan = res.data;
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ .el-table .cell {
    white-space: initial !important;
}
/deep/ .el-table td {
    padding: 5px 0;
}
.detail_box {
    width: 100%;
    height: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
    .title {
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        border-bottom: 1px dashed #e5e5e5;
        padding-left: 20px;
        box-sizing: border-box;
    }
    .deliver_info {
        padding-bottom: 12px;
        border-bottom: 1px dashed #e5e5e5;
        padding-left: 20px;
        box-sizing: border-box;
        p {
            margin-top: 15px;
            span {
                display: inline-block;
                width: 85px;
                color: #666;
            }
        }
    }
    .el-table {
        flex: 1;
        padding-bottom: 60px;
    }
}
</style>
