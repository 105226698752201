<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column prop="" label="订单编号" min-width="150">
                <template slot-scope="scope">
                    <div
                        v-if="scope.row.contractStatus == 3"
                        :style="{
                            color: 'red',
                            cursor: 'pointer',
                        }"
                    >
                        {{ scope.row.orderNo || '- -' }}
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="该订单的关联合同已作废"
                            placement="bottom"
                        >
                            <i
                                v-if="scope.row.contractStatus == 3"
                                class="el-icon-warning"
                                style="color: #ff912a"
                            ></i>
                        </el-tooltip>
                    </div>
                    <div
                        v-else
                        @click="onDetail2(scope.row)"
                        :style="{
                            color: '#2370eb',
                            cursor: 'pointer',
                        }"
                    >
                        {{ scope.row.orderNo || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="客户名称" min-width="192">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="产品名称"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            $tableDataHandle.productCategory(
                                scope.row.productCategory
                            )
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="用户数"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        <span
                            v-if="
                                scope.row.productCategory == 1 ||
                                scope.row.productCategory == 2 ||
                                scope.row.productCategory == 4 ||
                                scope.row.productCategory == 10
                            "
                        >
                            {{ scope.row.orderNumber || '0' }} 用户
                        </span>
                        <span v-else> - - </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="年限"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        <span
                            v-if="
                                scope.row.productCategory == 1 ||
                                scope.row.productCategory == 2 ||
                                scope.row.productCategory == 4 ||
                                scope.row.productCategory == 3
                            "
                        >
                            {{ scope.row.term || 0 }} 年
                        </span>
                        <span v-else> - - </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="交付时间"
                min-width="150"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            scope.row.deliverTime
                                ? scope.row.deliverTime.split(' ')[0]
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="140">
                <template
                    slot-scope="scope"
                    v-if="scope.row.contractStatus != 3"
                >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onDetail(scope.row)"
                        v-if="btnP.detailsPopup"
                    >
                        详情
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onUpdate(scope.row)"
                        v-if="btnP.upData && scope.row.status == 1"
                    >
                        编辑
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onDeliver(scope.row)"
                        v-if="btnP.deliver && scope.row.status == 1"
                    >
                        交付
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { orderPlanList } from '@/api/order/order.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [{ bh: '11' }],
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum,
                pageSize,
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (!btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!btnP.searchUser && !btnP.depSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchVal.status == '未交付') {
                data.param.status = 1;
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '部门交付') {
                data.param.status = 2;
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '已交付') {
                data.param.status = 2;
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '异常') {
                data.param.contractStatus = 3;
                data.param.statusList = [1];
            } else {
                data.param.statusList = [1];
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            if (searchVal.productCategory) {
                data.param.productCategory = searchVal.productCategory;
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                data.param.adminId = searchVal.adminId;
            }
            orderPlanList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout();
                    });
                }
            });
        },
        onDetail(data) {
            this.$emit('onDetail', data);
        },
        onDetail2(data) {
            this.$emit('onDetail2', data);
        },
        onUpdate(data) {
            this.$emit('onUpdate', data);
        },
        onDeliver(data) {
            this.$emit('onDeliver', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(7) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #ff760f;
                font-size: 12px;
                border-radius: 50%;
                font-style: normal;
                text-align: center;
                line-height: 20px;
                transform: scale(0.8);
                color: #ff760f;
            }
        }
    }
}
.tooltip_con_bot {
    //   padding-top: 30px;
    p {
        margin-bottom: 5px;
        span {
            display: inline-block;
            width: 60px;
        }
    }
}
.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
</style>
