<template>
    <div class="order">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>计划筛选</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="请输入订单编号/客户名称"
                        v-model="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>

                    <span>产品名称</span>
                    <el-select
                        v-model="searchVal.productCategory"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData()"
                    >
                        <el-option label="腾讯企业邮箱" :value="1"></el-option>
                        <el-option label="阿里企业邮箱" :value="2"></el-option>
                        <el-option label="云产品" :value="3"></el-option>
                        <el-option label="网易企业邮箱" :value="4"></el-option>
                        <el-option label="网站" :value="5"></el-option>
                        <el-option label="投屏盒子" :value="6"></el-option>
                        <el-option label="微盘" :value="7"></el-option>
                        <el-option label="主机" :value="8"></el-option>
                        <el-option label="服务器" :value="9"></el-option>
                        <el-option label="会话存档" :value="10"></el-option>
                        <el-option label="企业微信" :value="11"></el-option>
                    </el-select>
                    <span>订单状态</span>
                    <el-radio-group v-model="searchVal.status" size="small">
                        <el-radio-button
                            label="未交付"
                            @click.native.prevent="clickStatus('未交付')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已交付"
                            @click.native.prevent="clickStatus('已交付')"
                        ></el-radio-button>
                        <el-radio-button
                            label="异常"
                            @click.native.prevent="clickStatus('异常')"
                        ></el-radio-button>
                    </el-radio-group>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 20px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <span v-if="btnP.depSearch">部门</span>
                <el-select
                    v-if="btnP.depSearch"
                    v-model="searchVal.departmentId"
                    size="small"
                    style="width: 170px"
                    clearable
                    placeholder="请选择"
                    @change="changeDepartmentId"
                >
                    <el-option
                        v-for="item in dempData"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span v-if="btnP.searchUser">成员</span>
                <el-select
                    v-if="btnP.searchUser"
                    v-model="searchVal.adminId"
                    size="small"
                    style="width: 170px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData()"
                >
                    <el-option
                        v-for="item in userData"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="table-title">
            订单列表
            <!-- <el-button
        type="primary"
        style="background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height:24px;
                color:#2370EB;
                border-color: #2370EB;
                float:right"
        >+ 新增计划</el-button
      > -->
        </div>
        <Table
            ref="table"
            @onUpdate="onUpdate"
            @onDeliver="onDeliver"
            @onDetail="onDetail"
            @onDetail2="onDetail2"
            @totalNum="totalNum"
        ></Table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 交付计划详情 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleDrawerClose"
            size="600px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    计划详情
                </div>
            </template>
            <Detail ref="detail" @close="handleDrawerClose"></Detail>
        </el-drawer>
        <!-- 编辑回款 -->
        <el-drawer
            :visible.sync="drawerUpdate"
            :direction="direction"
            :before-close="handleDrawerClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑交付计划
                </div>
            </template>
            <Edit ref="edit" @close="handleDrawerClose"></Edit>
        </el-drawer>
        <!-- 交付 -->
        <el-dialog
            :visible.sync="dialogDeliver"
            width="560px"
            :before-close="handleDialogClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    订单交付
                </div>
            </template>
            <div style="width: 60%; margin: 20px auto 60px">
                <Deliver ref="deliver"></Deliver>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button round @click="handleDialogClose" size="mini">取 消</el-button>
                <el-button type="primary" round @click="onSubmitDeliver" size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { roleList } from '@/api/user/clue';
import { updateOrderPlan } from '@/api/order/order.js';
import { closedCusAdd } from '@/api/closedCus/closedCus.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import Table from './table/table';
import Edit from './edit/edit.vue';
import Detail from './detail/detail.vue';
import Deliver from './deliver/deliver.vue';
export default {
    components: {
        // Details,
        Table,
        Edit,
        Detail,
        Deliver,
    },
    data() {
        return {
            btnP: {},
            searchVal: {
                adminId: '',
                status: '',
            },
            dempData: [],
            userData: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            tableRow: {},
            drawerUpdate: false,
            drawerDetail: false,
            dialogDeliver: false,
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.getData();
            if (btn.depSearch) {
                this.getDempData();
            }
            if (btn.searchUser && !btn.depSearch) {
                this.getUserList(sessionStorage.getItem('departmentId'));
            }
        },
        totalNum(num) {
            this.total = num;
        },
        getData() {
            this.$refs.table.getData(this.searchVal, this.currentPage, this.pagesize, this.btnP);
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        onDetail2(row) {
            this.$router.push({
                path: 'condetails',
                query: {
                    index: 4,
                    id: row.contractId,
                },
            });
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
            });
        },
        changeDepartmentId() {
            this.searchVal.adminId = '';
            this.currentPage = 1;
            this.getData();
            this.getUserList(this.searchVal.departmentId);
        },
        // 获取部门成员
        getUserList(departmentId) {
            this.searchVal.adminId = '';
            let data = {
                param: {
                    departmentId,
                },
            };
            roleList(data).then((res) => {
                this.userData = res.data;
            });
        },
        //新增
        onUpdate(row) {
            this.drawerUpdate = true;
            setTimeout(() => {
                this.$refs.edit.getData(row);
            }, 0);
        },
        // 详情

        onDetail(row) {
            this.drawerDetail = true;
            setTimeout(() => {
                this.$refs.detail.getData(row);
            }, 0);
        },
        clickStatus(e) {
            e === this.searchVal.status
                ? (this.searchVal.status = '')
                : (this.searchVal.status = e);
            this.getTableData();
        },
        // 交付
        onDeliver(row) {
            this.dialogDeliver = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.deliver.getData(row);
            }, 0);
        },
        onSubmitDeliver() {
            let data = {
                param: {
                    id: this.$refs.deliver.$data.orderplan.id,
                    openTime: this.$refs.deliver.$data.form.openTime,
                    expireTime: this.$refs.deliver.$data.form.expireTime,
                    status: 2,
                    adminAccount: this.$refs.deliver.$data.form.adminAccount,
                    corpId: this.$refs.deliver.$data.form.corpId,
                    placeBackstage: this.$refs.deliver.$data.form.placeBackstage,
                },
            };
            if (!this.$refs.deliver.$data.form.openTime) {
                return this.$message.error('请选择开通时间');
            }
            if (!this.$refs.deliver.$data.form.expireTime) {
                return this.$message.error('请选择到期时间');
            }
            // if (this.tableRow.productCategory == 1) {
            //     if (!this.$refs.deliver.$data.form.companyName) {
            //         return this.$message.error('请输入客户名称');
            //     }
            // }
            if (
                this.tableRow.productCategory == 1 ||
                this.tableRow.productCategory == 2 ||
                this.tableRow.productCategory == 3 ||
                this.tableRow.productCategory == 12
            ) {
                console.log(123);
                if (!this.$refs.deliver.$data.form.companyName) {
                    return this.$message.error('请输入客户名称');
                }
                if (
                    this.$refs.deliver.$data.form.placeBackstage === '' ||
                    this.$refs.deliver.$data.form.placeBackstage === undefined ||
                    this.$refs.deliver.$data.form.placeBackstage === null
                ) {
                    return this.$message.error('请选择下单后台');
                }
            }
            updateOrderPlan(data).then((res) => {
                if (res.code == 200) {
                    if (
                        this.$refs.deliver.$data.orderplan.productCategory == 1 ||
                        this.$refs.deliver.$data.orderplan.productCategory == 2 ||
                        this.$refs.deliver.$data.orderplan.productCategory == 3 ||
                        this.$refs.deliver.$data.orderplan.productCategory == 12
                    ) {
                        this.closedCusAdd();
                    } else {
                        this.$message.success('操作成功');
                        this.handleDialogClose();
                        this.$refs.deliver.$data.form = {};
                    }
                }
            });
        },
        closedCusAdd() {
            let data = {
                param: {
                    reportTime: this.$refs.deliver.$data.form.openTime,
                    reportExpireTime: this.$refs.deliver.$data.form.expireTime,
                    companyName: this.$refs.deliver.$data.form.companyName,
                    saleAdminId: this.$refs.deliver.$data.orderplan.adminId,
                    departmentId: this.$refs.deliver.$data.orderplan.departmentId,
                    placeBackstage: this.$refs.deliver.$data.form.placeBackstage,
                    customerId: this.$refs.deliver.$data.orderplan.customerId,
                },
            };
            if (!this.$refs.deliver.$data.form.openTime) {
                return this.$message.error('请选择开通时间');
            }
            if (!this.$refs.deliver.$data.form.expireTime) {
                return this.$message.error('请选择到期时间');
            }
            if (this.tableRow.productCategory == 1) {
                if (!this.$refs.deliver.$data.form.companyName) {
                    return this.$message.error('请输入客户名称');
                }
                data.param.type = 1;
                data.param.number = this.$refs.deliver.$data.orderplan.orderNumber;
                if (this.$refs.deliver.$data.form.corpId) {
                    data.param.corpId = this.$refs.deliver.$data.form.corpId;
                }
            }
            if (this.tableRow.productCategory == 2) {
                if (this.$refs.deliver.$data.form.adminAccount) {
                    data.param.adminAccount = this.$refs.deliver.$data.form.adminAccount;
                }
                if (!this.$refs.deliver.$data.form.companyName) {
                    return this.$message.error('请输入客户名称');
                }
                data.param.number = this.$refs.deliver.$data.orderplan.orderNumber;
                data.param.type = 2;
            }
            if (this.tableRow.productCategory == 12) {
                if (this.$refs.deliver.$data.form.domain) {
                    data.param.domain = this.$refs.deliver.$data.form.domain;
                }
                if (!this.$refs.deliver.$data.form.companyName) {
                    return this.$message.error('请输入客户名称');
                }
                data.param.type = 3;
                data.param.number = this.$refs.deliver.$data.orderplan.orderNumber;
            }
            if (
                this.tableRow.productCategory == 1 ||
                this.tableRow.productCategory == 2 ||
                this.tableRow.productCategory == 3 ||
                this.tableRow.productCategory == 12
            ) {
                if (
                    this.$refs.deliver.$data.form.placeBackstage === '' ||
                    this.$refs.deliver.$data.form.placeBackstage === undefined ||
                    this.$refs.deliver.$data.form.placeBackstage === null
                ) {
                    return this.$message.error('请选择下单后台');
                }
            }
            closedCusAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialogClose();
                    this.$refs.deliver.$data.form = {};
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                adminId: '',
                status: '',
            };
            this.currentPage = 1;
            this.getData();
        },
        handleDrawerClose() {
            this.drawerUpdate = false;
            this.drawerDetail = false;
            this.getData();
        },
        handleDialogClose() {
            this.dialogDeliver = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
