var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deliver_box"},[_c('el-form',{attrs:{"label-position":"left","label-width":"85px","size":"small"}},[_c('el-form-item',{attrs:{"label":"产品名称"}},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.productCategory( _vm.orderplan.productCategory ))+" ")])]),_c('el-form-item',{attrs:{"label":"客户"}},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.orderplan.companyName || '- -')+" ")])]),_c('el-form-item',{attrs:{"label":"营业执照"}},[_c('div',{staticClass:"info"},[(
                        _vm.orderplan.businessLicenseUrl &&
                        (_vm.orderplan.businessLicenseUrl.indexOf('.jpg') !=
                            -1 ||
                            _vm.orderplan.businessLicenseUrl.indexOf('.png') !=
                                -1 ||
                            _vm.orderplan.businessLicenseUrl.indexOf('.gif') !=
                                -1)
                    )?_c('img',{staticStyle:{"width":"40px"},attrs:{"src":_vm.orderplan.businessLicenseUrl,"preview-src-list":[_vm.orderplan.businessLicenseUrl]},on:{"click":function($event){return _vm.onPreview(_vm.orderplan.businessLicenseUrl)}}}):_vm._e(),(
                        _vm.orderplan.businessLicenseUrl &&
                        (_vm.orderplan.businessLicenseUrl.indexOf('.pdf') !=
                            -1 ||
                            _vm.orderplan.businessLicenseUrl.indexOf('.PDF') !=
                                -1)
                    )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(_vm.orderplan.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(
                        _vm.orderplan.businessLicenseUrl &&
                        _vm.orderplan.businessLicenseUrl.indexOf('.doc') != -1
                    )?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(_vm.orderplan.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()])]),_c('el-form-item',{attrs:{"label":"附件"}},[(
                    _vm.orderplan.contractAttachmentBOList &&
                    _vm.orderplan.contractAttachmentBOList.length > 0
                )?_c('div',{staticClass:"info"},_vm._l((_vm.orderplan.contractAttachmentBOList),function(item){return _c('span',{key:item.id,staticClass:"cont",staticStyle:{"margin-right":"8px"}},[(
                            item.attachmentUrl &&
                            (item.attachmentUrl.indexOf('.jpg') != -1 ||
                                item.attachmentUrl.indexOf('.png') != -1 ||
                                item.attachmentUrl.indexOf('.gif') != -1)
                        )?_c('img',{staticStyle:{"width":"40px"},attrs:{"src":item.attachmentUrl,"preview-src-list":[item.attachmentUrl]},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}}):_vm._e(),(
                            item.attachmentUrl &&
                            (item.attachmentUrl.indexOf('.pdf') != -1 ||
                                item.attachmentUrl.indexOf('.PDF') != -1)
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(
                            item.attachmentUrl &&
                            item.attachmentUrl.indexOf('.doc') != -1
                        )?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()])}),0):_vm._e()]),_c('el-form-item',{attrs:{"label":"用户数"}},[_c('div',{staticClass:"info"},[(
                        _vm.orderplan.productCategory == 1 ||
                        _vm.orderplan.productCategory == 2 ||
                        _vm.orderplan.productCategory == 4 ||
                        _vm.orderplan.productCategory == 10
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.orderplan.orderNumber || 0)+" 用户 ")]):_c('span',[_vm._v(" - - ")])])]),_c('el-form-item',{attrs:{"label":"年限"}},[_c('div',{staticClass:"info"},[(
                        _vm.orderplan.productCategory == 1 ||
                        _vm.orderplan.productCategory == 2 ||
                        _vm.orderplan.productCategory == 4 ||
                        _vm.orderplan.productCategory == 3
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.orderplan.term || 0)+" 年 ")]):_c('span',[_vm._v(" - - ")])])]),_c('el-form-item',{attrs:{"label":"开通时间","required":""}},[_c('el-date-picker',{staticStyle:{"width":"240px"},attrs:{"value-format":"yyyy-MM-dd HH:mm:ss","type":"date","placeholder":"选择日期"},model:{value:(_vm.form.openTime),callback:function ($$v) {_vm.$set(_vm.form, "openTime", $$v)},expression:"form.openTime"}})],1),_c('el-form-item',{attrs:{"label":"到期时间","required":""}},[_c('el-date-picker',{staticStyle:{"width":"240px"},attrs:{"value-format":"yyyy-MM-dd HH:mm:ss","type":"date","picker-options":_vm.expireTimeOption,"placeholder":"选择日期"},model:{value:(_vm.form.expireTime),callback:function ($$v) {_vm.$set(_vm.form, "expireTime", $$v)},expression:"form.expireTime"}})],1),(
                _vm.orderplan.productCategory == 1 ||
                _vm.orderplan.productCategory == 2 ||
                _vm.orderplan.productCategory == 3 ||
                _vm.orderplan.productCategory == 12
            )?_c('el-form-item',{attrs:{"label":"下单名称","required":""}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"输入下单名称"},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})],1):_vm._e(),(
                _vm.orderplan.productCategory == 3 ||
                _vm.orderplan.productCategory == 12
            )?_c('el-form-item',{attrs:{"label":"域名","required":""}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"输入域名"},model:{value:(_vm.form.domain),callback:function ($$v) {_vm.$set(_vm.form, "domain", $$v)},expression:"form.domain"}})],1):_vm._e(),(_vm.orderplan.productCategory == 1)?_c('el-form-item',{attrs:{"label":"CorpID"}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"输入CorpID"},model:{value:(_vm.form.corpId),callback:function ($$v) {_vm.$set(_vm.form, "corpId", $$v)},expression:"form.corpId"}})],1):_vm._e(),(_vm.orderplan.productCategory == 2)?_c('el-form-item',{attrs:{"label":"管理员账号"}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"输入管理员账号"},model:{value:(_vm.form.adminAccount),callback:function ($$v) {_vm.$set(_vm.form, "adminAccount", $$v)},expression:"form.adminAccount"}})],1):_vm._e(),(
                _vm.orderplan.productCategory == 1 ||
                _vm.orderplan.productCategory == 2 ||
                _vm.orderplan.productCategory == 3 ||
                _vm.orderplan.productCategory == 12
            )?_c('el-form-item',{attrs:{"label":"下单后台","required":""}},[_c('el-select',{staticStyle:{"width":"240px"},attrs:{"size":"small","clearable":"","placeholder":"请选择下单后台"},model:{value:(_vm.form.placeBackstage),callback:function ($$v) {_vm.$set(_vm.form, "placeBackstage", $$v)},expression:"form.placeBackstage"}},[(_vm.orderplan.productCategory == 1)?_c('el-option',{attrs:{"label":"伽搜boss","value":0}}):_vm._e(),(_vm.orderplan.productCategory == 2)?_c('el-option',{attrs:{"label":"钱邮","value":1}}):_vm._e(),(
                        _vm.orderplan.productCategory == 3 ||
                        _vm.orderplan.productCategory == 12
                    )?_c('el-option',{attrs:{"label":"腾讯云","value":2}}):_vm._e(),(
                        _vm.orderplan.productCategory == 3 ||
                        _vm.orderplan.productCategory == 12
                    )?_c('el-option',{attrs:{"label":"阿里云","value":3}}):_vm._e(),(
                        _vm.orderplan.productCategory == 3 ||
                        _vm.orderplan.productCategory == 12
                    )?_c('el-option',{attrs:{"label":"925","value":4}}):_vm._e(),(
                        _vm.orderplan.productCategory == 3 ||
                        _vm.orderplan.productCategory == 12
                    )?_c('el-option',{attrs:{"label":"华为云","value":5}}):_vm._e(),(_vm.orderplan.productCategory == 2)?_c('el-option',{attrs:{"label":"云梦","value":6}}):_vm._e(),(_vm.orderplan.productCategory == 2)?_c('el-option',{attrs:{"label":"华中","value":7}}):_vm._e(),(_vm.orderplan.productCategory == 2)?_c('el-option',{attrs:{"label":"山总","value":8}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }